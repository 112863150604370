
  .bodyLogin{

    content: '';
   height: 100%;
   width: 95%; 
   
     position: absolute;   
   background: url('./Amen.jpeg');
    background-position: center ; 
    background-size: cover;  
   
 
   

 } 