ul>li{
  list-style-type: none;  
  font-size: 20px;
 
  margin-left: 0;
  line-height: 35px;
}
ul>li:hover{
text-decoration: underline;
color:red;
}
p{
  font-size: 20px; 
}