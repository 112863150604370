body {
	background:#fff;
}

a.border.nav-link.border-light.rounded.mr-1 {
  color: #3F729B;
}

a.border.nav-link.border-light.rounded.mr-1:hover {
  color: #1C2331;
}

.btn.btn-sm.indigo:hover{
  color: #fff;
}

.nav-link.active{
  background-color: rgba(255, 255, 255, 0.1);
}

#categories > .col-md-4.mb-5{
  margin-bottom: 8rem !important;
}
.sectionPage {
  position: relative;
  top: -15px;
  margin-bottom: -200px;
 

}

.cat> ul > li {
  line-height: 30px;
  list-style-type: none;
  text-decoration: underline;
color: #A9A9A9;
font-size: 12px;
}
.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  max-width: "100%";
}
.pagination > .active > a{
  background-color:#3F729B ;
  border-color: #3F729B ;
  color: #fff;
}
.pagination > li > a{
  border: 1px solid#3F729B  ;
  border-radius: 2em;
  padding: 5px 5px;
  margin: 0.2em;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #3F729B  ;
  border-color: #3F729B e;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: #3F729B;
  text-size-adjust: 1em;
  
  border-radius: 2em;

}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}
