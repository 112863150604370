.nav-item{
    text-decoration-color: black; 
    
}
.nav>ul>li{
  font-size: 5px;
}
.red-text{
    border-radius: 30px;
     padding:10px ;
     margin-right : 10px; 

}
.pagination{
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
    font-size: 1.2rem;
    width: fit-content;
    gap: 5px;
    
    cursor: pointer;
  
  }
  
  
  .pagination .page-num:hover{
 
    background-color: #1a6985;
    color: white;
    text-align: center;
  
  }
  
  .pagination .active{
   
    text-align: center;
  
    background-color: #1a6985;
    color: white;
  
  }
@media screen and (max-width : 768px)
{
.logo{
    display:none;   
}
.societe.d-flex{
flex-direction: column;
}
.Nav.d-flex{
    flex-direction: column;  
}
}